import Layout from "@/page/index/index";
export default [
  {
    path: "/wel",
    component: Layout,
    redirect: "/wel/index",
    children: [
      {
        path: "index",
        name: "首页",
        component: () => import(/* webpackChunkName: "views" */ "@/page/wel"),
      },
    ],
  },
  {
    path: "/info",
    component: Layout,
    redirect: "/info/index",
    children: [
      {
        path: "index",
        name: "个人信息",
        component: () =>
          import(/* webpackChunkName: "page" */ "@/views/admin/user/info"),
      },
    ],
  },
  {
    path: "/content",
    component: Layout,
    name: "内容管理v",
    children: [
      // {
      //   path: "/content/sisters/among",
      //   name: "排行信息编辑",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "page" */ "@/views/content/sisters/among"
      //     ),
      // },
      // {
      //   path: "/content/recommend/recommendRedact",
      //   name: "推荐信息编辑", //内容管理/推荐管理/推荐信息编辑
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "page" */ "@/views/content/recommend/recommendRedact"
      //     ),
      // },
      {
        path: "/content/management/details",
        name: "内容详情管理", //内容管理/视屏管理/视频编辑
        component: () =>
          import(
            /* webpackChunkName: "page" */ "@/views/content/management/details"
          ),
      },
      // {
      //   path: "/content/management/component/analysis",
      //   name: "数据管理",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "page" */ "@/views/content/management/component/analysis"
      //     ),
      // },
    ],
  },
  // {
  //   path: "/user",
  //   component: Layout,
  //   name: "用戶管理",
  //   children: [
  //     {
  //       path: "/user/details/basicsInfo",
  //       name: "用户基础信息",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "page" */ "@/views/user/details/basicsInfo"
  //         ),
  //     },
  //   ],
  // },
  {
    path: "/user",
    component: Layout,
    name: "会员管理v",
    children: [
      {
        path: "/user/detailsCY/index",
        name: "会员详情",
        component: () =>
          import(/* webpackChunkName: "page" */ "@/views/user/detailsCY/index"),
      },
    ],
  },
  {
    path: "/distributor-statistics",
    component: Layout,
    name: "渠道数据统计",
    children: [
      {
        path: "/distributor-statistics/package-upload/details",
        name: "代理统计详情",
        component: () =>
          import(
            /* webpackChunkName: "page" */ "@/views/distributor-statistics/package-upload/details.vue"
          ),
      },
    ],
  },
  {
    path: "/distributor-manage",
    component: Layout,
    name: "渠道商管理v",
    children: [
      {
        path: "distributorlist/webmasterDetails/index",
        name: "查看详情",
        component: () =>
          import(
            /* webpackChunkName: "page" */ "@/views/distributor-manage/distributorlist/webmasterDetails/index"
          ),
      },
      {
        path: "/distributor-manage/distributorlist/dataStatistics/installPackageDataStatistics",
        name: "安装包数据统计",
        component: () =>
          import(
            /* webpackChunkName: "page" */ "@/views/distributor-manage/distributorlist/dataStatistics/installPackageDataStatistics"
          ),
      },
      {
        path: "/distributor-manage/distributorlist/dataStatistics/projectStatistice",
        name: "基础统计表",
        component: () =>
          import(
            /* webpackChunkName: "page" */ "@/views/distributor-manage/distributorlist/dataStatistics/projectStatistice"
          ),
      },
      {
        path: '/distributor-manage/distributorlist/report/index',
        name: '渠道数据统计表',
        component: () =>
          import(
            /* webpackChunkName: "page" */ '@/views/distributor-manage/distributorlist/report/index'
          ),
      },
      {
        path: '/distributor-manage/distributorlist/reduceSettings/index',
        name: '渠道扣量配置',
        component: () =>
          import(
            /* webpackChunkName: "page" */ '@/views/distributor-manage/distributorlist/reduceSettings/index'
          ),
      },
      {
        path: "/distributor-manage/distributorlist/distributorDetails/index",
        name: "代理详情",
        component: () =>
          import(
            /* webpackChunkName: "page" */ "@/views/distributor-manage/distributorlist/distributorDetails/index"
          ),
      },
      {
        path: "/distributor-manage/distributorlist/distributorUserList/index",
        name: "用户列表信息",
        component: () =>
          import(
            /* webpackChunkName: "page" */ "@/views/distributor-manage/distributorlist/distributorUserList/index"
          ),
      },
      {
        path: "/distributor-manage/distributorlist/distributorUserList/distributorUserInfo/index",
        name: "用户信息",
        component: () =>
          import(
            /* webpackChunkName: "page" */ "@/views/distributor-manage/distributorlist/distributorUserList/distributorUserInfo/index"
          ),
      },
    ],
  },
  {
    path: "/marketing/adlog",
    component: Layout,
    name: "营销中心v",
    children: [
      {
        path: "/marketing/adlog/details",
        name: "广告详情",
        component: () =>
          import(
            /* webpackChunkName: "page" */ "@/views/marketing/adlog/details"
          ),
      },
    ],
  },
  {
    path: "/setting",
    component: Layout,
    name: "系统管理v",
    children: [
      {
        path: "/admin/SMsMgr/smsTemplate",
        name: "短信模版",
        component: () =>
          import(
            /* webpackChunkName: "page" */ "@/views/admin/SMsMgr/smsTemplate"
          ),
      },
    ],
  },
];
