const getters = {
  tag: (state) => state.tags.tag,
  website: (state) => state.common.website,
  userInfo: (state) => state.user.userInfo,
  theme: (state) => state.common.theme,
  themeName: (state) => state.common.themeName,
  isShade: (state) => state.common.isShade,
  isCollapse: (state) => state.common.isCollapse,
  keyCollapse: (state, getters) =>
    getters.screen > 1 ? getters.isCollapse : false,
  screen: (state) => state.common.screen,
  isLock: (state) => state.common.isLock,
  isFullScreen: (state) => state.common.isFullScreen,
  lockPasswd: (state) => state.common.lockPasswd,
  tagList: (state) => state.tags.tagList,
  tagWel: (state) => state.tags.tagWel,
  access_token: (state) => state.user.access_token,
  refresh_token: (state) => state.user.refresh_token,
  expires_in: (state) => state.user.expires_in,
  roles: (state) => state.user.roles,
  permissions: (state) => state.user.permissions,
  menu: (state) => state.user.menu,
  menuAll: (state) => state.user.menuAll,
  dictionary: (state) => state.common.dictionary,
  classifyOptions: (state) => state.common.classifyOptions,
  levelData: (state) => state.common.levelData,
  googleAuth: (state) => state.common.googleAuth,
  withdrawCount: (state) => state.record.withdrawCount,
  adminTag: (state) => state.common.adminTag,
  department: (state) => state.common.department,
  extensionWithdrawOrderCount: (state) => state.distributorManage.extensionWithdrawOrderCount,
  warningInfo: (state) => state.common.warningInfo,
  warningTime: (state) => state.common.warningTime,
  rowID: (state) => state.user.rowID,
  distrMobile: (state) => state.distributorManage.distrMobile,
  busPlatformInfo: (state) => state.common.busPlatformInfo,
};
export default getters;
